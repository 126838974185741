/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Input, Modal, ModalContent, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User, Pagination, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { classNames, Constants, formatAmount, fromPhotos, fromStorage } from '../../utils';
import { PayrollService } from '../../services';
import ModalEarning from './modal-earning';
import ModalPayment from './modal-payment';
import ModalSendReceipt from './modal-send-receipt';
import ModalDiscount from './modal-discount';
import { IconExport } from '../../assets/icons';

const MODAL_ACTION = {
  NONE: 0,
  EARNING: 1,
  PAYMENT: 2,
  SEND_RECEIPT: 3,
  DISCOUNT: 4,
  REMOVE_DISCOUNT: 5,
  REMOVE_EARNING: 6,
};

const GENERATE = {
  SEND: 1,
  DOWNLOAD: 2,
}

const Calculation = () => {

  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { banks, canResetFilter, filters, data, dataSupervisor, earnings, filterBy, addEarnings, reload, goToPage, pagination, isLoading, generateReceipt, exportToExcel } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedItem(order);
    setModalAction(action);
  }

  const closeModal = () => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
  }

  const onGeneratePayment = async (item) => {
    //Comentada validacion el 20-02-2025
    // if (item.orders?.some(x => !Number(x?.value_hour)))
    //   return toast.error('Debe ingresar los valores en el módulo de tienda antes de registrar el pago');
    onSelectItem(item, MODAL_ACTION.PAYMENT);
  }

  const removeDiscount = async () => {
    try {
      await PayrollService.deleteDiscount({ discount_id: selectedItem?.discount?.id });
      toast.success('Descuento eliminado con éxito');
      reload(true);
      closeModal();

    } catch (error) {
      toast.error(String(error));
    }
  }

  const removeEarning = async () => {
    try {
      await PayrollService.deleteOtherEarning({ other_earning_id: selectedItem?.earning?.id });
      toast.success('Ingreso eliminado con éxito');
      reload(true);
      closeModal();

    } catch (error) {
      toast.error(String(error));
    }
  }

  const isCleaner = filters.level_id === Constants.LEVELS.CLEANER;
  const isSupervisor = filters.level_id === Constants.LEVELS.SUPERVISOR;

  return (
    <>
      <Modal
        size="md"
        isOpen={modalAction === MODAL_ACTION.EARNING}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalEarning
            item={selectedItem}
            earnings={earnings}
            // onSuccess={form => {
            //   addEarnings(form);
            //   closeModal();
            // }}
            onSuccess={() => {
              reload(isSupervisor ? true : false);
              closeModal();
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalAction === MODAL_ACTION.PAYMENT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalPayment
            item={selectedItem}
            banks={banks}
            earnings={earnings}
            onSuccess={data => {
              onSelectItem({ ...selectedItem, payment_date: data.date, id: data.id }, MODAL_ACTION.SEND_RECEIPT);
              reload(true);
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.SEND_RECEIPT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalSendReceipt
            item={selectedItem}
            earnings={earnings}
            onDownload={() => generateReceipt(selectedItem, GENERATE.DOWNLOAD)}
            onSuccess={() => generateReceipt(selectedItem, GENERATE.SEND)}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.DISCOUNT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalDiscount
            item={selectedItem}
            onSuccess={() => {
              reload(true);
              closeModal();
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.REMOVE_DISCOUNT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
          <ModalBody>
            <p>¿Estás seguro de eliminar este descuento?</p>
          </ModalBody>
          <ModalFooter className="justify-evenly">
            <Button variant="light" onPress={closeModal}>Cancelar</Button>
            <Button color="primary" onPress={removeDiscount}>Aceptar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={modalAction === MODAL_ACTION.REMOVE_EARNING}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
          <ModalBody>
            <p>¿Estás seguro de eliminar este ingreso?</p>
          </ModalBody>
          <ModalFooter className="justify-evenly">
            <Button variant="light" onPress={closeModal}>Cancelar</Button>
            <Button color="primary" onPress={removeEarning}>Aceptar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
        exportToExcel={() => exportToExcel()}
      />

      <div className="flex gap-1 text-white font-semibold">
        <div
          className={classNames('ml-4 px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.CLEANER ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.CLEANER, 'level_id')}
        >
          <span>Limpiadores</span>
        </div>

        <div
          className={classNames('px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.SUPERVISOR ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.SUPERVISOR, 'level_id')}
        >
          <span>Supervisores</span>
        </div>
      </div>

      {isCleaner && data.map((item, itemIdx) => {
        const cleaner = item?.user //Datos del limpiador
        const periodInit = item.period_init;
        const itemsLength = item?.orders?.length;

        return (
          <Fragment key={itemIdx}>
            <Table
              aria-label="Desempeño Semanal"
              classNames={{
                base: 'mb-4',
                wrapper: 'p-2 gap-0',
                th: 'whitespace-pre-line border-b-0',
                td: 'border-t-1 first:border-r-1 border-collapse ',
                tr: 'last:border-b-1',
              }}
              topContent={(
                <div className="flex justify-between">
                  <div className="flex items-center" rowSpan={itemsLength}>
                    <div className="h-full flex px-4 py-2 items-center bg-default-100 w-48">
                      <p className="font-semibold text-foreground-500">Prestador de servicio</p>
                    </div>
                    <div className="px-4 py-2 bg-[#DEF6FF]">
                      <User
                        classNames={{ name: 'truncate', description: 'truncate' }}
                        avatarProps={{ showFallback: true, radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
                        name={cleaner?.person?.fullName}
                        description={cleaner?.email}
                      />
                    </div>
                  </div>
                  <div rowSpan={itemsLength} align="right" className="flex items-center gap-4">
                    <div>
                      <p className="font-semibold">
                        Período{'\n'}{ moment(item.period_init).format('DD-MM-YYYY') } al{'\n'}{ moment(item.period_finish).format('DD-MM-YYYY') }
                      </p>
                    </div>

                    {item.status === Constants.PAYROLL.STATUS.PENDING ? (
                      <>
                        <Button
                          className="text-primary border-primary font-semibold"
                          variant="bordered"
                          size="sm"
                          onClick={() => onSelectItem(item, MODAL_ACTION.EARNING)}
                        >
                          Registrar otro ingreso
                        </Button>

                        <Button
                          className="bg-[#5DAD4A] text-white font-semibold"
                          size="sm"
                          onClick={() => onGeneratePayment(item)}
                        >
                          Registrar pago
                        </Button>
                      </>
                    ) : item.status === Constants.PAYROLL.STATUS.PAID ? (
                      <span className="bg-red text-white font-semibold py-1 px-3 rounded text-sm">
                        PAGADO
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            >

              <TableHeader>
                <TableColumn>Fecha</TableColumn>
                <TableColumn>Tiendas{'\n'}asignadas</TableColumn>
                <TableColumn>Número de{'\n'}tienda</TableColumn>
                <TableColumn>Tipo{'\n'}tienda</TableColumn>
                <TableColumn>Valor día $</TableColumn>
                <TableColumn>Hrs./min{'\n'}Trabajadas</TableColumn>
                <TableColumn>Valor Hrs./min{'\n'}Trabajadas</TableColumn>
                <TableColumn>Subtotal</TableColumn>
                <TableColumn>Otros{'\n'}ingresos $</TableColumn>
                <TableColumn>Otros{'\n'}egresos $</TableColumn>
                <TableColumn>Neto{'\n'}Total $</TableColumn>
                <TableColumn />
              </TableHeader>
              <TableBody>
                {item?.orders?.map((order, orderIdx) => {
                  const isPerHour = order?.store?.payment_type === Constants.STORE.PAYMENT_TYPE.PER_HOUR;

                  if (orderIdx === 0) return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      {/* <TableCell>{ order?.date }</TableCell> */}
                      {/* Ahora mostrando fecha de finalizacion de la ejecucion */}
                      <TableCell>{ order?.date_finish }</TableCell> 
                      <TableCell>{ order?.store?.name }</TableCell>
                      <TableCell>{ order?.store?.number }</TableCell>
                      <TableCell>{ isPerHour ? 'Horas' : 'Jornada' }</TableCell>
                      <TableCell>{ isPerHour ? '' : order?.store?.value_day }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsTrabajadas : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>
                        <span className={classNames(order?.isDiscount ? 'text-red-500' : '')}>
                          { formatAmount(order?.subtotal) }
                        </span>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.totalOtherEarnings) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.totalDiscount) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength}>
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.total) }</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        {item.status === Constants.PAYROLL.STATUS.PENDING ? (
                          <Button
                            className="bg-[#D9A300] text-white font-semibold"
                            size="sm"
                            onClick={() => onSelectItem({ ...order, user: item.user }, MODAL_ACTION.DISCOUNT)}
                          >
                            Registrar descuento
                          </Button>
                        ): (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );

                  return (
                    <TableRow key={`${itemIdx}-${orderIdx}`} className={classNames(order?.isDiscount ? 'bg-gray-100' : '')}>
                      <TableCell>
                        {!!order?.isDiscount && (
                          <span>{ moment(order?.discount?.discount_date).format('DD/MM/YYYY') }</span>
                        )}
                        {!!order?.isOtherEarning && (
                          <span>{ moment(order?.earning?.created_at).format('DD/MM/YYYY') }</span>
                        )}
                        {(!order?.isDiscount && !order?.isOtherEarning) && (
                          <span>{ order?.date }</span>
                        )}
                      </TableCell>
                      <TableCell>{ order?.store?.name }</TableCell>
                      <TableCell>{ order?.store?.number }</TableCell>
                      <TableCell>{ isPerHour ? 'Horas' : 'Jornada' }</TableCell>
                      <TableCell>{ isPerHour ? '' : order?.store?.value_day }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsTrabajadas : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>
                        {!!order?.isDiscount && (
                          <span className="text-red">-{ formatAmount(order?.discount?.discount) }</span>
                        )}
                        {!!order?.isOtherEarning && (
                          <span className="text-green">{ formatAmount(order?.earning?.amount) }</span>
                        )}
                        {(!order?.isDiscount && !order?.isOtherEarning) && (
                          <span>{ formatAmount(order?.subtotal) }</span>
                        )}
                      </TableCell>
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell className="bg-white">
                        {item.status === Constants.PAYROLL.STATUS.PENDING && (
                          <>
                            {!!order?.isDiscount && (
                              <Button
                                className="bg-red text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem(order, MODAL_ACTION.REMOVE_DISCOUNT)}
                              >
                                Eliminar descuento
                              </Button>
                            )}

                            {!!order?.isOtherEarning && (
                              <Button
                                className="bg-red text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem(order, MODAL_ACTION.REMOVE_EARNING)}
                              >
                                Eliminar ingreso
                              </Button>
                            )}

                            {(!order?.isDiscount && !order?.isOtherEarning) && (
                              <Button
                                className="bg-[#D9A300] text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem({ ...order, user: item.user }, MODAL_ACTION.DISCOUNT)}
                              >
                                Registrar descuento
                              </Button>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {(!isLoading && data.length === 0) && (
              <div className="w-full py-4">
                <p className="text-center text-gray-500">No se encontraron resultados</p>
              </div>
            )}
          </Fragment>
        )
      })}

      {isSupervisor && dataSupervisor.map((item, itemIdx) => {
        const supervisor = item?.user //Datos del supervisor
        const periodInit = item?.period_init;
        const itemsLength = item?.stores?.length;

        return (
          <Fragment key={itemIdx}>
            <Table
              aria-label="Desempeño Semanal"
              classNames={{
                base: 'mb-4',
                wrapper: 'p-2',
                th: 'whitespace-pre-line',
                td: 'border-t-1 first:border-r-1 border-collapse',
                tr: 'border-b-1',
              }}
              topContent={
                <div className="flex items-center justify-between">
                  <div className="max-w-min bg-[#DEF6FF] px-3 py-2">
                    <User
                      classNames={{ name: 'truncate', description: 'truncate' }}
                      avatarProps={{ showFallback: true, radius: 'full', src: supervisor?.person?.photo ? fromPhotos(supervisor.person.photo) : null }}
                      name={supervisor?.person?.fullName}
                      description={supervisor?.email}
                    />
                  </div>

                  <div rowSpan={itemsLength} align="right" className="flex items-center gap-4">
                    <div className="font-semibold">
                      <span>Tiendas asignadas </span>
                      <span>{ item?.stores?.length } - </span>
                      <span>
                        Período{'\n'}{ moment(item.period_init).format('DD-MM-YYYY') } al{'\n'}{ moment(item.period_finish).format('DD-MM-YYYY') }
                      </span>
                    </div>

                    {item.status === Constants.PAYROLL.STATUS.PENDING ? (
                      <>
                        <Button
                          className="text-primary border-primary font-semibold"
                          variant="bordered"
                          size="sm"
                          onClick={() => onSelectItem(item, MODAL_ACTION.EARNING)}
                        >
                          Registrar otro ingreso
                        </Button>

                        <Button
                          className="bg-[#5DAD4A] text-white font-semibold"
                          size="sm"
                          onClick={() => onGeneratePayment(item)}
                        >
                          Registrar pago
                        </Button>
                      </>
                    ) : item.status === Constants.PAYROLL.STATUS.PAID ? (
                      <span className="bg-red text-white font-semibold py-1 px-3 rounded text-sm">
                        PAGADO
                      </span>
                    ) : null}
                  </div>
                </div>
              }
            >
              <TableHeader>
                <TableColumn className="w-48">Tiendas{'\n'}asignadas</TableColumn>
                <TableColumn>Número de{'\n'}Tienda</TableColumn>
                <TableColumn>Valor{'\n'}Tienda</TableColumn>
                <TableColumn>Valor{'\n'}Limpiador</TableColumn>
                <TableColumn>Comisión{'\n'}Supervisor</TableColumn>
                <TableColumn>Visitas {'\n'}Realizadas</TableColumn>
                <TableColumn>Ingresos{'\n'}Subtotal</TableColumn>
                <TableColumn>Otros{'\n'}ingresos</TableColumn>
                <TableColumn>Otros{'\n'}egresos</TableColumn>
                <TableColumn>Total{'\n'}Ingresos</TableColumn>
                <TableColumn />
              </TableHeader>
              <TableBody>
                {item?.stores?.map((store, orderIdx) => {
                  if (orderIdx === 0) return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell className="w-48">{ store?.name }</TableCell>
                      <TableCell>{ store?.number }</TableCell>
                      <TableCell>{ formatAmount(store?.value_store, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_cleaner, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_supervisor, '$') }</TableCell>
                      <TableCell>{ store?.visits_count }</TableCell>
                      <TableCell>
                        <span className={classNames(store?.isDiscount ? 'text-red-500' : '', store?.isOtherEarning ? 'text-green-500' : '')}>
                          { formatAmount(store?.subtotal) }
                        </span>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.totalOtherEarnings) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.totalDiscount) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength}>
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.total) }</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        {item.status === Constants.PAYROLL.STATUS.PENDING ? (
                          <Button
                            className="bg-[#D9A300] text-white font-semibold"
                            size="sm"
                            onClick={() => onSelectItem({ ...store, user: item.user }, MODAL_ACTION.DISCOUNT)}
                          >
                            Registrar descuento
                          </Button>
                        ): (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  );

                  return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell>{ store?.name }</TableCell>
                      <TableCell>{ store?.number }</TableCell>
                      <TableCell>{ formatAmount(store?.value_store, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_cleaner, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_supervisor, '$') }</TableCell>
                      <TableCell>{ store?.visits_count }</TableCell>
                      <TableCell>
                        {!!store?.isDiscount && (
                          <span className="text-red">-{ formatAmount(store?.discount?.discount) }</span>
                        )}
                        {!!store?.isOtherEarning && (
                          <span className="text-green">{ formatAmount(store?.earning?.amount) }</span>
                        )}
                        {(!store?.isDiscount && !store?.isOtherEarning) && (
                          <span>{ formatAmount(store?.subtotal) }</span>
                        )}
                      </TableCell>
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell className="bg-white">
                        {item.status === Constants.PAYROLL.STATUS.PENDING && (
                          <>
                            {!!store?.isDiscount && (
                              <Button
                                className="bg-red text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem(store, MODAL_ACTION.REMOVE_DISCOUNT)}
                              >
                                Eliminar descuento
                              </Button>
                            )}

                            {!!store?.isOtherEarning && (
                              <Button
                                className="bg-red text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem(store, MODAL_ACTION.REMOVE_EARNING)}
                              >
                                Eliminar ingreso
                              </Button>
                            )}

                            {(!store?.isDiscount && !store?.isOtherEarning) && (
                              <Button
                                className="bg-[#D9A300] text-white font-semibold"
                                size="sm"
                                onClick={() => onSelectItem({ ...store, user: item.user }, MODAL_ACTION.DISCOUNT)}
                              >
                                Registrar descuento
                              </Button>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {(!isLoading && dataSupervisor.length === 0) && (
              <div className="w-full py-4">
                <p className="text-center text-gray-500">No se encontraron resultados</p>
              </div>
            )}
          </Fragment>
        )
      })}

      {(!isLoading && ((isCleaner && data.length > 0) || (isSupervisor && dataSupervisor.length > 0))) && (
        <div className="flex w-full justify-center mt-4">
          <Pagination
            showControls
            variant="bordered"
            page={pagination.page}
            total={pagination.pages}
            onChange={goToPage}
          />
        </div>
      )}
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter, exportToExcel }) => {
  const initialFilter = {
    search: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[17rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder="Nombre del trabajador, tienda"
        onClear={() => onChange('', 'search')}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        min="2025-01-01"
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full lg:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        min="2025-01-01"
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
      <Button
        color="primary"
        startContent={<img src={IconExport} className="w-6 h-6 invert" alt="" />}
        onClick={() => {
          exportToExcel();
        }}
      >
        Exportar a excel
      </Button>
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: 5,
    search: '',
    since: '',
    until: '',
    level_id: Constants.LEVELS.CLEANER,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: 5,
  };

  const [data, setData] = useState([]);
  const [dataSupervisor, setDataSupervisor] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);
  const [earnings, setEarnings] = useState([]);
  const [banks, setBanks] = useState([]);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.getPayroll(filters);
      const { data, ...rest } = response;
      const parsedData = [];

      data.forEach(item => {
        const { cleaner: user, first_fortnight, second_fortnight } = item;

        if (first_fortnight && !Array.isArray(first_fortnight)) {
          parsedData.push({ user, ...first_fortnight });
        }

        if (second_fortnight && !Array.isArray(second_fortnight)) {
          parsedData.push({ user, ...second_fortnight });
        }
      });

      parsedData.forEach(x => {
        const orders = [];
        let discount = 0;
        let otherEarnings = 0;

        x?.orders?.forEach(o => {
          let totalDiscount = o?.discounts?.reduce((acc, x) => acc + Number(x.discount), 0);
          let totalOtherEarnings = o?.other_earnings?.reduce((acc, x) => acc + Number(x.amount), 0);
          discount += totalDiscount;
          otherEarnings += totalOtherEarnings;

          orders.push({
            ...o,
            totalDiscount,
            totalOtherEarnings,
            isDiscount: false,
            isOtherEarning: false,
          });

          o?.other_earnings?.forEach(e => orders.push({
            ...o,
            earning: e,
            isDiscount: false,
            isOtherEarning: true,
          }));
          o?.discounts?.forEach(d => orders.push({
            ...o,
            discount: d,
            isDiscount: true,
            isOtherEarning: false,
          }));
        });

        x.orders = orders;
        x.totalDiscount = discount;
        x.totalOtherEarnings = otherEarnings;
        x.total = ((Number(x.totalEarnings) || 0) + (Number(otherEarnings) || 0) - (Number(discount) || 0));
      });

      setData(parsedData.map((x, i) => ({ ...x, index: i })));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const fetchDataSupervisor = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.getPayrollSupervisor(filters);
      const { data, ...rest } = response;
      const parsedData = [];

      data.forEach(item => {
        const { supervisor: user, ...rest } = item;

        Object.values(rest).forEach(x => {
          if (x.first_fortnight && !Array.isArray(x.first_fortnight)) {
            parsedData.push({ user, ...x.first_fortnight });
          }
  
          if (x.second_fortnight && !Array.isArray(x.second_fortnight)) {
            parsedData.push({ user, ...x.second_fortnight });
          }
        });
      });

      parsedData.forEach(x => {
        const stores = [];
        let discount = 0;
        let otherEarnings = 0;

        x?.stores?.forEach(s => {
          let totalDiscount = s?.discounts?.reduce((acc, x) => acc + Number(x.discount), 0);
          let totalOtherEarnings = s?.other_earnings?.reduce((acc, x) => acc + Number(x.amount), 0);
          discount += totalDiscount;
          otherEarnings += totalOtherEarnings;

          stores.push({
            ...s,
            totalDiscount,
            totalOtherEarnings,
            isDiscount: false,
            isOtherEarning: false,
          });

          s?.other_earnings?.forEach(e => stores.push({
            ...s,
            earning: e,
            isDiscount: false,
            isOtherEarning: true,
          }));
          s?.discounts?.forEach(d => stores.push({
            ...s,
            discount: d,
            isDiscount: true,
            isOtherEarning: false,
          }));
        });

        x.stores = stores;
        x.totalDiscount = discount;
        x.totalOtherEarnings = otherEarnings;
        x.total = ((Number(x.totalEarnings) || 0) + (Number(otherEarnings) || 0) - (Number(discount) || 0));
      });

      setDataSupervisor(parsedData.map((x, i) => ({ ...x, index: i })));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const generateReceipt = async (item, action) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const form = {
        payment_id: item.id,
        send_to_mail: action === GENERATE.SEND,
      };

      const response = await PayrollService.generateReceipt(form);

      if (action === GENERATE.DOWNLOAD) {
        const res = await fetch(fromPhotos(response.url));
        const blob = await res.blob();
        const extension = String(response.url).split('.').pop();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Recibo - ${moment.now()}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        toast.success('Recibo enviado con éxito');
      }

    } catch (error) {
      onError(String(error));
    }

    setCanFetch(true);
  }

  const getBanks = async () => {
    try {
      const banks = await PayrollService.getBanks();
      setBanks(banks);

    } catch (error) {
      onError(String(error));
    }
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else{
      if (filters.level_id === Constants.LEVELS.SUPERVISOR) {
        fetchDataSupervisor();
      } else {
        fetchData();
      }
    }
  }

  const exportToExcel = async () => {
    try {
      const excelFilters = {
        ...filters,
        to_excel: true
      };

      // Llamar al servicio, dependiendo si quiero obtener limpiadores o supervisores
      let res = []
      if (filters.level_id === Constants.LEVELS.SUPERVISOR) {
        res = await PayrollService.getPayrollSupervisor(excelFilters);
      }
      else {
        res = await PayrollService.getPayroll(excelFilters);
      }
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Reporte de nomina';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      console.error('Error al exportar:', error);
      // Agregar manejo de errores (ej: mostrar notificación)
      alert('Error al generar el reporte. Intente nuevamente.');
    }
  };

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    setData([]);

    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  const addEarnings = (earning) => {
    setEarnings(s => {
      // Verificar si existe un ingreso adicional para el mismo trabajador
      const existingEarningIdx = s.findIndex(x => x.cleaner_id === earning.cleaner_id && x.order_id === earning.order_id);
      if (existingEarningIdx !== -1) {
        // Si existe, se sustituye por el nuevo ingreso
        s[existingEarningIdx] = earning;
        return s;
      }

      // Si no existe, se agrega al final
      return [ ...earnings, earning ];
    });
  }

  useEffect(() => {
    if (filters.level_id === Constants.LEVELS.SUPERVISOR) {
      fetchDataSupervisor();
    } else {
      fetchData();
    }

    getBanks();
  }, [filters]);

  return {
    addEarnings,
    banks,
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    dataSupervisor,
    earnings,
    filterBy,
    filters,
    generateReceipt,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
    exportToExcel,
  }
}

export default Calculation;