/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button, Listbox, ListboxItem, User, Checkbox } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { IconX } from '@tabler/icons-react';
import moment from 'moment';
import { CleanersService, OrdersService, StoresService } from '../../services';
import { clone, Constants, fromPhotos } from '../../utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ModalCreate = ({ order, onSuccess, onClose }) => {
  const isEditing = !!order;
  const { clearCleaners, clearStores, cleaners, onSubmit, searchCleaner, searchStore, stores } = useFetch(isEditing);
  const [form, setForm] = useState({
    id: null,
    store_id: '',
    store: null,
    cleaners: [],
    starts_at: '',
    ends_at: '',
    time_starts_at: '',
    time_ends_at: '',
    priority: '',
    _searchStore: '',
    _searchCleaner: '',
    night_shift: Constants.ORDER.NIGHT_SHIFT.NO,
  });

  const getBusyCleaners = () => {
    return cleaners.filter(cleaner => {
      return (cleaner?.orders || [])
        .filter(order => isEditing ? (order.id !== form.id) : true)
        .some(order => {
          const current = {
            start: moment(form.starts_at, 'YYYY-MM-DD'),
            end: moment(form.ends_at, 'YYYY-MM-DD'),
            timeStart: moment(form.time_starts_at, 'HH:mm'),
            timeEnd: moment(form.time_ends_at, 'HH:mm'),
          }

          const start = moment(order.starts_at, 'YYYY-MM-DD');
          const end = moment(order.ends_at, 'YYYY-MM-DD');
          const timeStart = moment(order.time_starts_at, 'HH:mm');
          const timeEnd = moment(order.time_ends_at, 'HH:mm');

          const isBetweenStartDate = current.start.isBetween(start, end, null, '[]');
          const isBetweenEndDate = current.end.isBetween(start, end, null, '[]');
          const isBetweenStartTime = current.timeStart.isBetween(timeStart, timeEnd);
          const isBetweenEndTime = current.timeEnd.isBetween(timeStart, timeEnd);
          const isBetweenStartTimeInverse = timeStart.isBetween(current.timeStart, current.timeEnd);
          const isBetweenEndTimeInverse = timeEnd.isBetween(current.timeStart, current.timeEnd);
          const isSameTime = current.timeStart.isSame(timeStart) && current.timeEnd.isSame(timeEnd);

          return (
            (isBetweenStartDate || isBetweenEndDate) &&
            (isBetweenStartTime || isBetweenEndTime || isBetweenStartTimeInverse || isBetweenEndTimeInverse || isSameTime)
          )
        });
    }).map(x => String(x.id));
  }

  const isValidSchedule = (showError = true) => {
    const onError = (msg) => {
      if (showError) toast.error(msg);
      return false;
    }

    if (!form.starts_at || !form.ends_at || !form.time_starts_at || !form.time_ends_at) {;
      return onError('Primero debe seleccionar una fecha y hora');
    }

    return true;
  }

  const onChange = (value, target) => {
    if (target === '_searchCleaner') {
      if (value) searchCleaner(value)
      else clearCleaners();
    }

    if (target === '_searchStore') {
      if (value) searchStore(value)
      else clearStores();
    }

    if (target === 'store_id') {
      const store = stores.find(store => store.id === Number(value));
      if (!store) return;
      setForm(s => ({ ...s, store, store_id: Number(value), _searchStore: store.name }));
      return clearStores();
    }

    setForm(s => ({ ...s, [target]: value }));
  }

  const onSelectCleaner = (item) => {
    const exists = form.cleaners.some(x => x.id === Number(item.currentKey));
    if (exists) return;

    const cleaner = cleaners.find(x => x.id === Number(item.currentKey));
    setForm(s => ({ ...s, cleaners: [...s.cleaners, cleaner], _searchCleaner: '' }))
    clearCleaners();
  }

  const onRemoveCleaner = (id) => {
    const cleaners = form.cleaners.filter(x => x.id !== id);
    onChange(cleaners, 'cleaners');
  }

  const submit = async () => {
    const data = {
      ...clone(form),
      store: undefined,
      cleaners: undefined,
      cleaner_ids: form.cleaners.map(x => x.id),
    };
    const isSuccess = await onSubmit(data);
    if (isSuccess) onSuccess();
  }

  useEffect(() => {
    if (!order) return;

    setForm({
      ...form,
      id: order?.id,
      store_id: order?.store_id,
      store: order?.store,
      cleaners: order?.order_cleaners.map(x => x.cleaner),
      starts_at: order?.starts_at,
      ends_at: order?.ends_at,
      time_starts_at: order?.time_starts_at,
      time_ends_at: order?.time_ends_at,
      priority: order?.priority,
      night_shift: order?.night_shift,
      _searchStore: order?.store?.name,
    });
  }, [order]);

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">
        { order?.id ? 'Editar Orden' : 'Crear Orden' }
      </ModalHeader>
      <ModalBody>
        <div className="relative">
          <Input
            classNames={{ inputWrapper: 'border-1 h-12' }}
            label="Tienda"
            variant="bordered"
            isClearable
            onClear={() => {
              clearStores();
              onChange('', 'store_id');
              onChange(null, 'store');
            }}
            value={form._searchStore}
            onValueChange={v => onChange(v, '_searchStore')}
          />
          <div className={classNames(stores.length ? '':'hidden', 'w-full border-small px-1 py-2 rounded-small absolute z-20 bg-white')}>
            <Listbox
              aria-label="Tiendas"
              variant="flat"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={[]}
              onSelectionChange={v => onChange(v.currentKey, 'store_id')}
              classNames={{ base: 'max-h-48 overflow-auto' }}
            >
              {stores.map(item => (
                <ListboxItem key={item.id}>{ item.name }</ListboxItem>
              ))}
            </Listbox>
          </div>
        </div>

        <div className="flex-[2] pt-5">
          <Checkbox
            isSelected={form.night_shift === Constants.ORDER.NIGHT_SHIFT.YES ? true : false}
            onClick={() => onChange(form.night_shift === Constants.ORDER.NIGHT_SHIFT.YES ? Constants.ORDER.NIGHT_SHIFT.NO : 1, 'night_shift')}
          >
            <p className="text-sm text-primary">Turno Nocturno</p>
          </Checkbox>
        </div>

        <div>
          <p className="text-sm text-primary">Fecha programada</p>
          <p><small>Puedes programar el día, la semana o el mes al trabajador.</small></p>
        </div>

        <div className="flex justify-between gap-4">
          <Input
            type="date"
            classNames={{
              inputWrapper: 'border-1',
              input: `pr-0 text-${!!form.starts_at ? '[]':'foreground-400'}`,
            }}
            label="Inicio"
            placeholder=" "
            variant="bordered"
            value={form.starts_at}
            onValueChange={v => onChange(v, 'starts_at')}
            min={new Date().toISOString().split('T')[0]}
          />
          <Input
            type="date"
            classNames={{
              inputWrapper: 'border-1',
              input: `pr-0 text-${!!form.ends_at ? '[]':'foreground-400'}`,
            }}
            label="Culminación"
            placeholder=" "
            variant="bordered"
            value={form.ends_at}
            onValueChange={v => onChange(v, 'ends_at')}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        <p className="text-sm text-primary">Hora programada</p>
        <div className="flex justify-between gap-4">
          <Input
            type="time"
            classNames={{
              inputWrapper: 'border-1',
              input: `pr-0 text-${!!form.time_starts_at ? '[]':'foreground-400'}`,
            }}
            label="Inicio"
            placeholder=" "
            variant="bordered"
            value={form.time_starts_at}
            onValueChange={v => onChange(v, 'time_starts_at')}
          />
          <Input
            type="time"
            classNames={{
              inputWrapper: 'border-1',
              input: `pr-0 text-${!!form.time_ends_at ? '[]':'foreground-400'}`,
            }}
            label="Salida"
            placeholder=" "
            variant="bordered"
            value={form.time_ends_at}
            onValueChange={v => onChange(v, 'time_ends_at')}
          />
        </div>

        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Observaciones"
          variant="bordered"
          value={form.priority}
          onValueChange={v => onChange(v, 'priority')}
        />

        <div className="relative">
          <Input
            classNames={{ inputWrapper: 'border-1 h-12' }}
            label="Limpiador"
            variant="bordered"
            isClearable
            onClear={clearCleaners}
            disabled={!isValidSchedule(false)}
            value={form._searchCleaner}
            onValueChange={v => onChange(v, '_searchCleaner')}
          />
          <div className={classNames(cleaners.length ? '':'hidden', 'w-full border-small px-1 py-2 rounded-small absolute z-20 bg-white')}>
            <Listbox
              aria-label="Limpiadores"
              variant="flat"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={[]}
              disabledKeys={getBusyCleaners()}
              onSelectionChange={onSelectCleaner}
              classNames={{ base: 'max-h-48 overflow-auto' }}
            >
              {cleaners.map(item => (
                <ListboxItem key={item.id} >{ item?.person?.fullName }</ListboxItem>
              ))}
            </Listbox>
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 py-2">
          {form.cleaners.map(cleaner => (
            <div key={cleaner.id} className="w-full flex justify-between items-center">
              <User
                avatarProps={{ showFallback: true, radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
                name={cleaner?.person?.fullName}
                description={cleaner?.email}
              />

              <IconX
                className="cursor-pointer text-red"
                onClick={() => onRemoveCleaner(cleaner.id)}
              />
            </div>
          ))}

          {!form.cleaners?.length && (
            <p className="py-4 self-center text-sm">Selecciona al menos un limpiador</p>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const useFetch = (isEditing) => {

  const [stores, setStores] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  const debounceTime = 500;
  const debounce = useRef();

  const onError = (msg) => toast.error(msg);

  const getStores = async (search) => {
    try {
      const res = await StoresService.findAll({ search });
      setStores(res.data);

    } catch (error) {
      onError(String(error));
    }
  }

  const getCleaners = async (search) => {
    try {
      const res = await CleanersService.findAllForSelect({ search, isPaginated: 1 });
      setCleaners(res.data);

    } catch (error) {
      onError(String(error));
    }
  }

  const isValidForm = (form) => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    const validCleaners = form.cleaner_ids.filter(x => !!x.id);
    const hasDuplicated = (new Set(validCleaners)).size !== validCleaners.length;

    if (hasDuplicated)
      return onError('No puede seleccionar el mismo limpiador múltiples veces');

    if (!form.store_id)
      return onError('Debe seleccionar una tienda');

    if (!form.starts_at)
      return onError('Debe seleccionar una fecha de inicio');

    if (isEditing) {
      // TODO: Definir las reglas con respecto a las fechas para cuando está editando

    } else {
      const isToday = moment(form.starts_at,'YYYY-MM-DD').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

      if (isToday && moment().diff(moment(form.starts_at,'YYYY-MM-DD'), 'days') > 0)
        return onError('La fecha de inicio no puede ser antes de la fecha actual');

      if (isToday && moment().diff(moment(form.time_starts_at,'HH:mm'), 'minutes') > 0)
        return onError('La hora de inicio no puede ser antes de la hora actual');

      if (moment(form.starts_at,'YYYY-MM-DD').diff(moment(form.ends_at,'YYYY-MM-DD'), 'days') > 0)
        return onError('La fecha de culminación no puede ser antes de la fecha de inicio');

      //Solo evaluo en horario Diurno
      if(form.night_shift === Constants.ORDER.NIGHT_SHIFT.NO){
        if (moment(form.time_starts_at,'HH:mm').diff(moment(form.time_ends_at,'HH:mm'), 'minutes') > 0)
          return onError('La hora de culminación no puede ser antes de la hora de inicio');
      }
    }

    if (!form.ends_at)
      return onError('Debe seleccionar una fecha de culminación');

    if (!form.time_starts_at)
      return onError('Debe seleccionar una hora de inicio');

    if (!form.time_ends_at)
      return onError('Debe seleccionar una hora de culminación');

    return true;
  }

  const onSubmit = async (order) => {
    if (!isValidForm(order)) return;

    try {
      isEditing
        ? await OrdersService.update(order)
        : await OrdersService.create(order);

      toast.success(`Orden ${isEditing ? 'editada':'creada'} con éxito`);

      return true;

    } catch (error) {
      onError(String(error));
      return false;
    }
  }

  const searchStore = (value) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      getStores(value);
    }, debounceTime);
  }

  const searchCleaner = (value) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      getCleaners(value);
    }, debounceTime);
  }

  return {
    cleaners,
    clearCleaners: () => setCleaners([]),
    clearStores: () => setStores([]),
    onSubmit,
    searchCleaner,
    searchStore,
    stores,
  }
}

export default ModalCreate;
