/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, Select, SelectItem, User } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { Constants, formatAmount, fromPhotos } from '../../utils';
import { PayrollService } from '../../services';

const ModalPayment = ({ item, banks, onSuccess }) => {
  const isSupervisor = item?.user?.level_id === Constants.LEVELS.SUPERVISOR;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    cleaner_id: item.user.id,
    amount: 0,
    bank_from: '',
    bank_to: '',
    date: '',
    reference: '',
    period_init: isSupervisor ? item.period_init : null,
    period_finish: isSupervisor ? item.period_finish : null,
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.cleaner_id)
      return onError('Debe seleccionar un limpiador');

    if (!form.amount)
      return onError('El monto no puede ser cero');

    if (!form.bank_to)
      return onError('Debe seleccionar el banco de destino');

    if (!form.bank_from)
      return onError('Debe seleccionar el banco de emisor');

    if (!form.date)
      return onError('Debe seleccionar una fecha');

    // Validar que el form.amount no sea superior a store.value_store de ninguna order
    // const ordersToCheck = !isSupervisor ? item.orders : item.stores;
    // if (ordersToCheck && ordersToCheck.length > 0) {
    //   for (const orderItem of ordersToCheck) {
    //     // Cada objeto orderItem tiene una propiedad "store"
    //     const storeValue = Number(orderItem.store.value_store);
    //     if (Number(form.amount) > storeValue) {
    //       return onError(
    //         `No puede pagar más del valor tienda configurado de la tienda ${orderItem.store.name}`
    //       );
    //     }
    //   }
    // }

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const data = {
        ...form,
        bank_from: +form.bank_from,
        bank_to: +form.bank_to,
        amount: +form.amount,
      };

      const res = await PayrollService.createPayment(data);
      toast.success('Pago registrado con éxito');
      data.id = res.id;
      onSuccess(data);

    } catch (error) {
      console.log(error)
      toast.error(error?.message);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    const items = isSupervisor ? item?.stores : item?.orders;

    const details = items?.filter(x => !x.isDiscount && !x.isOtherEarning)?.map(i => ({
      payroll_id: i?.payroll_id,
      amount: parseFloat(parseFloat(i?.subtotal).toFixed(2)) || 0,
    }));

    items?.filter(x => !!x.isOtherEarning)?.map(i => details.push({
      amount: parseFloat(parseFloat(i?.earning?.amount).toFixed(2)) || 0,
      description: i?.earning?.description,
    }));

    setForm(s => ({ ...s, amount: item.total, details }));
  }, []);

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Pago</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex justify-between gap-4 flex-1">
            <div className="overflow-hidden">
              <User
                classNames={{
                  name: 'truncate text-md font-semibold',
                  description: 'truncate text-sm text-gray',
                }}
                avatarProps={{ showFallback: true, radius: 'full', src: item?.user?.person?.photo ? fromPhotos(item.user.person.photo) : null }}
                name={item?.user?.person?.fullName}
                description={item?.user?.email}
              />
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-[#202C4E]">{ formatAmount(form.amount) }</p>
              <p className="text-[13px]">Monto Pagado $</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Select
              label="Banco destino"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.bank_to ? [form.bank_to]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'bank_to')}
            >
              {banks.map((el) => (
                <SelectItem key={el.id} value={el.id}>{ el.name }</SelectItem>
              ))}
            </Select>

            <Select
              label="Banco emisor"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.bank_from ? [form.bank_from]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'bank_from')}
            >
              {banks.map((el) => (
                <SelectItem key={el.id} value={el.id}>{ el.name }</SelectItem>
              ))}
            </Select>

            <Input
              type="date"
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
                input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
              }}
              label="Fecha de pago"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.date}
              onValueChange={v => onChange(v, 'date')}
            />

            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Referencia"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.reference}
              onValueChange={v => onChange(v, 'reference')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onSubmit} isLoading={isLoading}>
          Guardar
        </Button>
      </ModalFooter>
    </div>
  )
}

export default ModalPayment;