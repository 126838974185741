/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, User } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { Constants, fromPhotos } from '../../utils';
import { PayrollService } from '../../services';
import { CurrencyInput } from '../../components';

const ModalDiscount = ({ item, onSuccess }) => {
  const isSupervisor = item?.user?.level_id === Constants.LEVELS.SUPERVISOR;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    cleaner_id: item.user.id,
    payroll_id: item?.payroll_id,
    discount: 0,
    discount_date: '',
    discount_comment: '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.cleaner_id)
      return onError('Debe seleccionar un limpiador');

    if (!form.discount)
      return onError('El monto no puede ser cero');

    if (form.discount < 0)
      return onError('El monto no puede ser negativo');

    const prevDiscounts = item?.discounts?.reduce((acc, x) => acc + Number(x.discount), 0)

    if ((form.discount + prevDiscounts) > item.subtotal)
      return onError('El monto no puede superar el monto total de la orden');

    if (!form.discount_date)
      return onError('Debe seleccionar una fecha');

    if (!form.discount_comment)
      return onError('Debe indicar el motivo del descuento');

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const data = {
        payroll_id: form.payroll_id,
        discounts: [{
          discount_date: form.discount_date,
          discount_comment: form.discount_comment,
          discount: +form.discount,
        }],
      };

      await PayrollService.createDiscount(data);
      toast.success('Descuento registrado con éxito');
      onSuccess(data);

    } catch (error) {
      toast.error(error);
    }

    setIsLoading(false);
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Descuento</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex justify-between gap-4 flex-1">
            <div className="overflow-hidden">
              <User
                classNames={{
                  name: 'truncate text-md font-semibold',
                  description: 'truncate text-sm text-gray',
                }}
                avatarProps={{ showFallback: true, radius: 'full', src: item?.user?.person?.photo ? fromPhotos(item.user.person.photo) : null }}
                name={item?.user?.person?.fullName}
                description={item?.user?.email}
              />
            </div>
            <div className="text-right">
              <p className="font-semibold">Tienda</p>
              <p className="font-semibold">{ isSupervisor ? item?.number : item?.store?.number }</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Input
              type="date"
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
                input: `pr-0 text-${!!form.discount_date ? '[]':'foreground-400'}`,
              }}
              label="Fecha"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.discount_date}
              onValueChange={v => onChange(v, 'discount_date')}
            />

            <CurrencyInput
              classNames={{ inputWrapper: 'border-1 !h-10 bg-white' }}
              label="Monto a descontar"
              placeholder="0,00"
              value={form.discount}
              onValueChange={v => onChange(v, 'discount')}
            />

            <Input
              classNames={{
                base: 'w-full col-span-2',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Motivo del descuento"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.discount_comment}
              onValueChange={v => onChange(v, 'discount_comment')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onSubmit} isLoading={isLoading}>
          Guardar
        </Button>
      </ModalFooter>
    </div>
  )
}

export default ModalDiscount;