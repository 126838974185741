import React, { Fragment } from 'react';
import { Button, Divider, ModalBody, ModalFooter, ModalHeader, User } from '@nextui-org/react';
import moment from 'moment';
import { clone, Constants, formatAmount } from '../../utils';

const ModalSendReceipt = ({ item, onDownload, onSuccess }) => {
  console.log('item: ', item)
  const isSupervisor = item.user.level_id === Constants.LEVELS.SUPERVISOR;
  const items = isSupervisor
    ? item?.stores
    : clone(item?.orders).sort((a, b) => {
      const aDate = a.isDiscount ? moment(a?.discount?.discount_date) : (a?.isOtherEarning ? moment(a?.earning?.created_at) : moment(a?.date, 'DD/MM/YYYY'));
      const bDate = b.isDiscount ? moment(b?.discount?.discount_date) : (b?.isOtherEarning ? moment(b?.earning?.created_at) : moment(b?.date, 'DD/MM/YYYY'));
      return aDate <= bDate ? -1 : 1;
    });

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col"></ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full px-4">
          <User
            classNames={{
              name: 'truncate text-md font-semibold',
              description: 'truncate text-sm text-gray',
            }}
            avatarProps={{ src: process.env.REACT_APP_BASE + 'img/logo.png', radius: 'none', className: 'bg-white' }}
            // name="Charlotte, NC"
            // description="RFC; 998554699"
          />

          <div className="my-4 flex flex-1 justify-between text-sm">
            <div>
              <p className="font-semibold">Prestador de servicio: { item?.user?.person?.fullName }</p>
              <p>DNI: { item?.user?.person?.document }</p>
            </div>
            <div className="text-right">
              <p className="font-semibold">Fecha emisión</p>
              <p>{ moment(item?.payment_date).format('DD-MM-YYYY') }</p>
            </div>
          </div>

          {isSupervisor ? (
            <div className="grid [&>p:nth-child(even)]:text-right text-sm" style={{ gridTemplateColumns: '5fr 2fr' }}>
              <p className="font-semibold">Concepto</p>
              <p className="font-semibold">Monto $</p>
              {items?.map((order, orderIdx) => (
                <Fragment key={orderIdx}>
                  {/* <p>
                    { order?.isDiscount ? 'Descuento' : (order?.isOtherEarning ? 'Otro ingreso' : 'Devengado') } { order?.name }
                  </p> */}
                  <p>
                    {order?.isDiscount ? (
                      <>
                        Descuento: {order?.store?.name}
                        <br />
                        {order?.discount?.discount_comment}
                      </>
                    ) : order?.isOtherEarning ? (
                      <>
                        Otro ingreso: {order?.store?.name}
                        <br />
                        {order?.earning?.description}
                      </>
                    ) : (
                      `${order?.store?.name}`
                    )}
                  </p>
                  <p>
                    {!!order?.isDiscount && (
                      <span className="text-red">-{ formatAmount(order?.discount?.discount) }</span>
                    )}
                    {!!order?.isOtherEarning && (
                      <span className="text-green">{ formatAmount(order?.earning?.amount) }</span>
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      formatAmount(order?.subtotal)
                    )}
                  </p>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className="grid [&>p:nth-child(3n)]:text-right text-sm" style={{ gridTemplateColumns: '2fr 5fr 2fr' }}>
              <p className="font-semibold">Fecha</p>
              <p className="font-semibold">Concepto</p>
              <p className="font-semibold">Monto $</p>
              {items?.map((order, orderIdx) => (
                <Fragment key={orderIdx}>
                  <p>
                    {!!order?.isDiscount && (
                      moment(order?.discount?.discount_date).format('DD/MM/YYYY')
                    )}
                    {!!order?.isOtherEarning && (
                      moment(order?.earning?.created_at).format('DD/MM/YYYY')
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      order?.date
                    )}
                  </p>
                  {/* <p>
                    { order?.isDiscount ? 'Descuento' : (order?.isOtherEarning ? 'Otro ingreso' : 'Devengado') } { order?.store?.name }
                  </p> */}
                  <p>
                    {order?.isDiscount ? (
                      <>
                        Descuento: {order?.store?.name}
                        <br />
                        {order?.discount?.discount_comment}
                      </>
                    ) : order?.isOtherEarning ? (
                      <>
                        Otro ingreso: {order?.store?.name}
                        <br />
                        {order?.earning?.description}
                      </>
                    ) : (
                      `${order?.store?.name}`
                    )}
                  </p>
                  <p>
                    {!!order?.isDiscount && (
                      <span className="text-red">-{ formatAmount(order?.discount?.discount) }</span>
                    )}
                    {!!order?.isOtherEarning && (
                      <span className="text-green">{ formatAmount(order?.earning?.amount) }</span>
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      formatAmount(order?.subtotal)
                    )}
                  </p>
                </Fragment>
              ))}
            </div>
          )}
        </div>

        <Divider className="my-2" />

        <div className="w-full px-4">
          <div
            className="grid [&>p:nth-child(even)]:text-right text-sm font-semibold gap-4 mb-4"
            style={{ gridTemplateColumns: '5fr 2fr' }}
          >
            <p>Pago por servicio $</p>
            <p>{ formatAmount(Number(item.totalEarnings) - Number(item.totalDiscount || 0)) }</p>
            <p>Otros ingresos</p>
            <p>{ formatAmount(item.totalOtherEarnings) }</p>
            <p>Otros egresos</p>
            <p>{ formatAmount(item.totalDiscounts) }</p>
          </div>

          <div
            className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex flex-1 justify-between items-center [&>p:nth-child(even)]:text-right text-sm font-bold text-primary gap-4 -mx-4"
          >
            <p>Total Pago por Servicio $</p>
            <p className="text-medium">{ formatAmount(item.total) }</p>
          </div>

          {/* <p className="my-4 text-sm text-center whitespace-pre-line">
            De no emitir reclamo, certifico haber recibido la cantidad{'\n'}indicada de salario neto, así como copia del presente recibo. 
          </p> */}
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="flat" color="primary" onPress={onDownload}>Descargar</Button>
        <Button color="primary" onPress={onSuccess}>Enviar recibo al usuario</Button>
      </ModalFooter>
    </div>
  )
}

export default ModalSendReceipt;