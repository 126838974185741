/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { Constants, formatAmount, fromPhotos, fromStorage } from '../../utils';
import { PayrollService } from '../../services';
import moment from 'moment';
import { IconExport } from '../../assets/icons';

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
};

const levels = [
  { label: 'Limpiador', value: Constants.LEVELS.CLEANER.toString() },
  { label: 'Supervisor', value: Constants.LEVELS.SUPERVISOR.toString() },
];

const Payments = () => {

  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, download, filterBy, reload } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedItem(order);
    setModalAction(action);
  }

  const closeModal = () => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
  }

  return (
    <>
      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.VIEW}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalSendReceipt
              item={selectedItem}
              onClose={() => {
                reload();
                onClose();
              }}
              onSuccess={() => {
                reload();
                onClose();
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table
        aria-label="Registro de pagos"
        classNames={{
          base: 'mb-4',
          wrapper: 'p-2',
          th: 'whitespace-pre-line',
          td: 'border-t-1 first:border-r-1 border-collapse',
          tr: 'border-b-1',
        }}
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primaryDark">Registro de pagos</h3>
            <Button
              color="primary"
              startContent={<img src={IconExport} className="w-6 h-6 invert" alt="" />}
              onClick={() => download()}
            >
              Exportar reporte
            </Button>
          </div>
        }
      >
        <TableHeader>
          <TableColumn className="w-48">Prestador de servicio</TableColumn>
          <TableColumn>Periodo pagado</TableColumn>
          <TableColumn>Fecha de pago</TableColumn>
          <TableColumn>Monto pagado $</TableColumn>
          <TableColumn>Banco emisor</TableColumn>
          <TableColumn>Cuenta bancaria</TableColumn>
          <TableColumn>Referencia</TableColumn>
          <TableColumn></TableColumn>
        </TableHeader>
        <TableBody items={data} emptyContent="No hay datos">
          {(item) => {
            return (
              <TableRow key={item.index}>
                <TableCell className="w-48 bg-[#DEF6FF]">
                  <div className="w-48 overflow-hidden">
                    <User
                      classNames={{ name: 'truncate', description: 'truncate' }}
                      avatarProps={{
                        showFallback: true,
                        radius: 'full',
                        src: item?.payment?.cleaner?.person?.photo ? fromPhotos(item.payment.cleaner.person.photo) : null
                      }}
                      name={item?.payment?.cleaner?.person?.fullName}
                      description={item?.payment?.cleaner?.email}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  { item?.payment_period || `${moment(item?.payment?.period_init).format('DD-MM-YYYY')} a\n${moment(item?.payment?.period_finish).format('DD-MM-YYYY')}` }
                </TableCell>
                <TableCell>{ moment(item?.payment?.date).format('DD-MM-YYYY') }</TableCell>
                <TableCell>{ formatAmount(item?.payment.amount) }</TableCell>
                <TableCell>{ item?.payment?.fromBank?.name }</TableCell>
                <TableCell>{ item?.payment?.cleaner?.bank?.account_number }</TableCell>
                <TableCell>{ item?.payment?.reference }</TableCell>
                <TableCell align="right" className="w-36">
                  <div className="flex flex-col gap-2 w-36">
                    <Button
                      className="text-white font-semibold"
                      color="primary"
                      size="sm"
                      onPress={() => onSelectItem(item, MODAL_ACTION.VIEW)}
                    >
                      Ver
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    search: '',
    searchStore: '',
    since: '',
    until: '',
    level_id: Constants.LEVELS.CLEANER.toString(),
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder="Nombre del trabajador"
        onClear={() => onChange('', 'search')}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      {/* <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Nombre de la tienda"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder=" "
        onClear={() => onChange('', 'searchStore')}
        variant="bordered"
        value={form.searchStore}
        onValueChange={v => onChange(v, 'searchStore')}
      /> */}
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        min="2025-01-01"
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full lg:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        min="2025-01-01"
      />
      <Select
        label="Tipo de Usuario"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[9rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={form.level_id ? [form.level_id]:[]}
        onSelectionChange={v => onChange(v.currentKey,'level_id')}
      >
        {levels.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalSendReceipt = ({ item }) => {
  const isSupervisor = item?.payment?.cleaner.level_id === Constants.LEVELS.SUPERVISOR;
  const originalOrders = item?.payment?.details.filter(x => !!x.payroll_id);

  let discount = 0;
  let otherEarnings = 0;
  const orders = [];

  // Extrae los decuentos de cada orden y los pone seguido de la misma
  originalOrders.forEach(x => {
    let totalDiscount = x?.payroll?.discounts?.reduce((acc, x) => acc + Number(x.discount), 0);
    let totalOtherEarnings = x?.payroll?.other_earnings?.reduce((acc, x) => acc + Number(x.amount), 0);
    discount += totalDiscount;
    otherEarnings += totalOtherEarnings;

    orders.push({
      ...x,
      totalDiscount,
      totalOtherEarnings,
      isDiscount: false,
      isOtherEarning: false,
    });

    x?.payroll?.other_earnings?.forEach(e => orders.push({
      ...x,
      earning: e,
      isDiscount: false,
      isOtherEarning: true,
    }));
    x?.payroll?.discounts?.forEach(d => orders.push({
      ...x,
      discount: d,
      isDiscount: true,
      isOtherEarning: false,
    }));
  });

  const totalAmount = originalOrders.reduce((acc, order) => acc + parseFloat(order.amount), 0) - discount;

  if (!isSupervisor) {
    orders.sort((a, b) => {
      const aDate = a.isDiscount
        ? moment(a?.discount?.discount_date)
        : (a.isOtherEarning ? moment(a?.earning?.created_at) : moment(a?.payroll?.orderExecution?.started_at));
  
      const bDate = b.isDiscount
        ? moment(b?.discount?.discount_date)
        : (b.isOtherEarning ? moment(b?.earning?.created_at) : moment(b?.payroll?.orderExecution?.started_at));
  
      return aDate <= bDate ? -1 : 1;
    });
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col"></ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full px-4">
          <User
            classNames={{
              name: 'truncate text-md font-semibold',
              description: 'truncate text-sm text-gray',
            }}
            avatarProps={{ src: process.env.REACT_APP_BASE + 'img/logo.png', radius: 'none', className: 'bg-white' }}
            // name="Charlotte, NC"
            // description="RFC; 998554699"
          />

          <div className="my-4 flex flex-1 justify-between text-sm">
            <div>
              <p className="font-semibold">Prestador de servicio: { item?.payment?.cleaner?.person?.fullName }</p>
              <p>DNI: { item?.payment?.cleaner?.person?.document }</p>
            </div>
            <div className="text-right">
              <p className="font-semibold">Fecha emisión</p>
              <p>{ moment(item?.payment?.date).format('DD-MM-YYYY') }</p>
            </div>
          </div>

          {isSupervisor ? (
            <div className="grid [&>p:nth-child(even)]:text-right text-sm" style={{ gridTemplateColumns: '5fr 2fr' }}>
              <p className="font-semibold">Concepto</p>
              <p className="font-semibold">Monto $</p>
              {orders?.map((order, orderIdx) => (
                <Fragment key={orderIdx}>
                  {/* <p>
                    { order?.isDiscount ? 'Descuento' : (order?.isOtherEarning ? 'Otro ingreso' : 'Devengado') } { order?.payroll?.store?.name }
                  </p> */}
                  <p>
                    {order?.isDiscount ? (
                      <>
                        Descuento: {order?.payroll?.store?.name}
                        <br />
                        {order?.discount?.discount_comment}
                      </>
                    ) : order?.isOtherEarning ? (
                      <>
                        Otro ingreso: {order?.payroll?.store?.name}
                        <br />
                        {order?.earning?.description}
                      </>
                    ) : (
                      `${order?.payroll?.store?.name}`
                    )}
                  </p>
                  <p>
                    {!!order?.isDiscount && (
                      <span className="text-red">-{ formatAmount(order?.discount?.discount) }</span>
                    )}
                    {!!order?.isOtherEarning && (
                      <span className="text-green">{ formatAmount(order?.earning?.amount) }</span>
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      formatAmount(order?.amount)
                    )}
                  </p>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className="grid [&>p:nth-child(3n)]:text-right text-sm" style={{ gridTemplateColumns: '2fr 5fr 2fr' }}>
              <p className="font-semibold">Fecha</p>
              <p className="font-semibold">Concepto</p>
              <p className="font-semibold">Monto $</p>
              {orders?.map((order, orderIdx) => (
                <Fragment key={orderIdx}>
                  <p>
                    {!!order?.isDiscount && (
                      moment(order?.discount?.discount_date).format('DD/MM/YYYY')
                    )}
                    {!!order?.isOtherEarning && (
                      moment(order?.earning?.created_at).format('DD/MM/YYYY')
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      moment(order?.payroll?.orderExecution?.started_at).format('DD/MM/YYYY')
                    )}
                  </p>
                  {/* <p>
                    { order?.isDiscount ? 'Descuento' : (order?.isOtherEarning ? 'Otro ingreso' : 'Devengado') } { order?.payroll?.store?.name }
                  </p> */}
                  <p>
                    {order?.isDiscount ? (
                      <>
                        Descuento: {order?.payroll?.store?.name}
                        <br />
                        {order?.discount?.discount_comment}
                      </>
                    ) : order?.isOtherEarning ? (
                      <>
                        Otro ingreso: {order?.payroll?.store?.name}
                        <br />
                        {order?.earning?.description}
                      </>
                    ) : (
                      `${order?.payroll?.store?.name}`
                    )}
                  </p>
                  <p>
                    {!!order?.isDiscount && (
                      <span className="text-red">-{ formatAmount(order?.discount?.discount) }</span>
                    )}
                    {!!order?.isOtherEarning && (
                      <span className="text-green">{ formatAmount(order?.earning?.amount) }</span>
                    )}
                    {(!order?.isDiscount && !order?.isOtherEarning) && (
                      formatAmount(order?.amount)
                    )}
                  </p>
                </Fragment>
              ))}
            </div>
          )}
        </div>

        <Divider className="my-2" />

        <div className="w-full px-4">
          <div
            className="grid [&>p:nth-child(even)]:text-right text-sm font-semibold gap-4 mb-4"
            style={{ gridTemplateColumns: '5fr 2fr' }}
          >
            <p>Pago por servicio $</p>
            <p>{ formatAmount(totalAmount) }</p>
            <p>Otros ingresos</p>
            <p>{ formatAmount(otherEarnings) }</p>
            <p>Otros egresos</p>
            <p>{ formatAmount(discount) }</p>
          </div>

          <div
            className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex flex-1 justify-between items-center [&>p:nth-child(even)]:text-right text-sm font-bold text-primary gap-4 -mx-4"
          >
            <p>Total Pago por Servicio $</p>
            <p className="text-medium">{ formatAmount(item.payment.amount) }</p>
          </div>

          {/* <p className="my-4 text-sm text-center whitespace-pre-line">
            De no emitir reclamo, certifico haber recibido la cantidad{'\n'}indicada de salario neto, así como copia del presente recibo. 
          </p> */}
        </div>
      </ModalBody>
    </div>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: 20,
    search: '',
    searchStore: '',
    since: '',
    until: '',
    level_id: Constants.LEVELS.CLEANER.toString(),
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: 20,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.getPayments(filters);
      const { data, ...rest } = response;
      setData(data.map((x, index) => ({ ...x, index })));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const download = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.downloadPayments(filters);
      if (!response) throw new Error('No hay datos para exportar');
      const link = document.createElement('a');
      link.href = fromStorage(response.url);
      link.target = '_blank';
      link.download = `Registro de Pagos - ${Date.now()}`;
      link.click();
      link.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    setData([]);

    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    download,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
  }
}

export default Payments;