import React, { useState } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, Select, SelectItem } from '@nextui-org/react';
import { CurrencyInput } from '../../components';
import toast from 'react-hot-toast';
import { PayrollService } from '../../services';
import { Constants, formatAmount } from '../../utils';

const ModalEarning = ({ item, onSuccess }) => {
  const isSupervisor = item.user.level_id === Constants.LEVELS.SUPERVISOR;
  // const isCleaner = item.user.level_id === Constants.LEVELS.CLEANER;

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    cleaner_id: item.user.id,
    payroll_id: '',
    period_init: item.period_init,
    amount: '',
    date: '',
    description: '',
  });
  
  const items = isSupervisor ? item?.stores : item?.orders;
  const payrolls = items
    ?.filter(x => !x.isDiscount && !x.isOtherEarning)
    ?.map(x => ({ id: x.payroll_id, name: `${x?.store?.name || x?.name} (${formatAmount(x.subtotal)})` }));

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.cleaner_id)
      return onError('Debe seleccionar un limpiador');

    if (!form.payroll_id)
      return onError('Debe seleccionar una tienda');

    if (!form.amount)
      return onError('El monto no puede ser cero');

    if (form.amount < 0)
      return onError('El monto no puede ser negativo');

    if (!form.date)
      return onError('Debe indicar la fecha');

    if (!form.description)
      return onError('Debe indicar el motivo del ingreso');

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const data = {
        payroll_id: +form.payroll_id,
        amount: form.amount,
        description: form.description,
        date: form.date,
      };

      await PayrollService.saveOtherEarning(data);
      toast.success('Ingreso registrado con éxito');
      onSuccess(data);

    } catch (error) {
      toast.error(error);
    }

    setIsLoading(false);
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Otro Ingreso</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="flex flex-col gap-4">
            <Select
              label="Tienda"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.payroll_id ? [form.payroll_id]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'payroll_id')}
            >
              {payrolls.map((el) => (
                <SelectItem key={el.id} value={el.id}>{ el.name }</SelectItem>
              ))}
            </Select>
            <CurrencyInput
              classNames={{ inputWrapper: 'border-1 !h-10 bg-white' }}
              label="Monto a asignar $"
              placeholder=" "
              value={form.amount}
              onValueChange={v => onChange(v, 'amount')}
            />
            <Input
              type="date"
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
                input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
              }}
              label="Fecha de pago"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.date}
              onValueChange={v => onChange(v, 'date')}
            />
            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Motivo"
              labelPlacement="outside"
              placeholder="Escribir"
              variant="bordered"
              value={form.description}
              onValueChange={v => onChange(v, 'description')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onSubmit} isLoading={isLoading}>Guardar</Button>
      </ModalFooter>
    </div>
  )
}

export default ModalEarning;